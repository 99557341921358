import React, { Component } from 'react'

export class Register extends Component {
    constructor(props) {
        super(props)
    
        document.title= "Create Your Account - News Admin"
    }
    
    render() {
        return (
            <div>
               <h1>Register Page</h1> 
            </div>
        )
    }
}

export default Register
