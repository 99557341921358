import React, { Component } from "react";

export class Dashboard extends Component {
  constructor(props) {
    super(props);

    document.title = "Welcome to News Admin";

    if (localStorage.getItem("id") === null) {
      window.location.replace("/");
    }
  }

  render() {
    return (
      <>
        <main className="container">
          <h2 className="my-3">Welcome to Admin Panel</h2>
        </main>
      </>
    );
  }
}

export default Dashboard;
