import React, { Component } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import axios from "axios";

export class AllNews extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allNews: [],
      notEmpty: false,
    };
  }

  componentDidMount() {
    this.getNews();
  }

  getNews = () => {
    axios
      .get(`${window.baseUrl}/all-news`)
      .then((response) => {
        if (response.data.status === 1) {
          this.setState({
            allNews: response.data.data,
            notEmpty: true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  render() {
    return (
      <>
        <main className="container mt-5">
          {this.state.notEmpty ? (<h2 className="mb-4">List of News</h2>) : (<p></p>)}

          <div className="row">
            {this.state.notEmpty ? (
              this.state.allNews.map((e) => (
                <div className="px-2 col-md-4 my-4">
                  <Card className="p-0">
                    <div className="p-0">
                      <CardMedia
                        component="img"
                        height="200"
                        image={e.imageUrl}
                        alt="green iguana"
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h6" component="div">
                          <a
                            href={e.postUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-decoration-none text-body"
                          >
                            {e.title.slice(0, 80)}
                          </a>
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {e.description.slice(0, 100)}
                        </Typography>
                        <Stack direction="row" className="pt-4">
                          <Chip
                            label={e.siteName}
                            size="small"
                            color="primary"
                          />
                        </Stack>
                        <Stack direction="row" className="pt-3">
                          <Chip label={e.pubDate} size="large" />
                        </Stack>
                      </CardContent>
                    </div>
                  </Card>
                </div>
              ))
            ) : (
              <h1 className="text-center py-4">No Data Found</h1>
            )}
          </div>
        </main>
      </>
    );
  }
}

export default AllNews;
