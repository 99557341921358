import React, { Component } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import LoadingButton from "@material-ui/lab/LoadingButton";
import { DataGrid } from "@mui/x-data-grid";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";

export class AddSubject extends Component {
  constructor(props) {
    super(props);

    this.state = {
      standardName: "",
      snackOpen: false,
      snackMessage: "",
      snackVarient: "",
      loading: false,
      standardData: [],
      deparmentData: [],
      subjectName: "",
      standardId: "",
      departmentId: "",
    };
  }

  componentDidMount() {
    this.getStandard();
    this.getDepartment();
  }

  getStandard = () => {
    axios
      .get(`${window.baseUrl}get-standard`)
      .then((response) => {
        if (response.data.status === 1) {
          console.log(response.data);
          this.setState({
            standardData: [...this.state.standardData, ...response.data.data],
          });
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getDepartment = () => {
    axios
      .get(`${window.baseUrl}get-department`)
      .then((response) => {
        if (response.data.status === 1) {
          this.setState({
            deparmentData: [...this.state.deparmentData, ...response.data.data],
          });
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = () => {
    this.setState({ loading: true });
    const formData = new FormData();

    formData.append("subjectName", this.state.subjectName);
    formData.append("standardId", this.state.standardId);
    formData.append("departmentId", this.state.departmentId);

    axios
      .post(`${window.baseUrl}add-subject`, formData)
      .then((response) => {
        console.log(response);
        if (response.data.status === 1) {
          console.log(response.data);
          this.setState(
            {
              loading: false,
              snackOpen: true,
              snackVarient: "success",
              subjectName: "",
              standardId: "",
              departmentId: "",
              snackMessage: response.data.message,
              deparmentData: [],
            },
            () => {
              this.getStandard();
              this.getDepartment();
            }
          );
        } else {
          this.setState({
            loading: false,
            snackOpen: true,
            snackVarient: "error",
            snackMessage: response.data.message,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleClose = () => {
    this.setState({
      snackOpen: false,
    });
  };

  render() {
    const columns = [
      { field: "id", headerName: "ID", width: 70 },
      { field: "title", headerName: "Standard Name", width: 200 },
      { field: "stream", headerName: "Stream Name", width: 200 },
    ];

    const rows = this.state.standardData;

    const Alert = React.forwardRef(function Alert(props, ref) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    return (
      <main className="container mt-5">
        <Snackbar
          open={this.state.snackOpen}
          autoHideDuration={3000}
          onClose={this.handleClose}
        >
          <Alert severity={this.state.snackVarient} sx={{ width: 400 }}>
            {this.state.snackMessage}
          </Alert>
        </Snackbar>
        <div className="w-100">
          <Card className="w-100 p-3">
            <CardContent>
              <h4 className="text-body">Manage Subject</h4>

              <div className="row">
                <div className="col-md-6">
                  <Card className="my-4">
                    <CardContent className="m-3">
                      <h5 className="text-body">Add Subject</h5>

                      <div className="mt-5 w-100">
                        <TextField
                          id="outlined-basic"
                          label="Subject Name"
                          variant="outlined"
                          className="w-100"
                          size="small"
                          name="subjectName"
                          value={this.state.subjectName}
                          onChange={this.onChange}
                        />
                      </div>

                      <div className="mt-4 w-100">
                        <FormControl fullWidth size="small">
                          <InputLabel id="demo-simple-select-label">
                            Select Standard
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={this.state.standardId}
                            label="Select Standard"
                            name="standardId"
                            onChange={this.onChange}
                          >
                            {this.state.standardData.map((e) => (
                              <MenuItem key={e.id} value={e.id}>
                                {e.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>

                      <div className="mt-4 w-100">
                        <FormControl fullWidth size="small">
                          <InputLabel id="demo-simple-select-label">
                            Select Department
                          </InputLabel>
                          <Select
                            labelId="department-select-label"
                            id="department-select"
                            value={this.state.departmentId}
                            label="Select Department"
                            name="departmentId"
                            onChange={this.onChange}
                          >
                            {this.state.deparmentData.map((e) => (
                              <MenuItem key={e.id} value={e.id}>
                                {e.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>

                      <div className="mt-4">
                        <LoadingButton
                          loading={this.state.loading}
                          variant="contained"
                          className="px-4"
                          onClick={this.onSubmit}
                        >
                          Submit
                        </LoadingButton>
                      </div>
                    </CardContent>
                  </Card>
                </div>
                <div className="col-md-6">
                  <Card className="my-4">
                    <CardContent>
                      <h5 className="text-body">List Standard</h5>

                      <div>
                        <div style={{ height: 400, width: "100%" }}>
                          <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                          />
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </main>
    );
  }
}

export default AddSubject;
