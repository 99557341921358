import React, { Component } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import LoadingButton from "@material-ui/lab/LoadingButton";
import { DataGrid } from "@mui/x-data-grid";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";

export class AddPaperBook extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFile: null,
      snackOpen: false,
      snackMessage: "",
      snackVarient: "",
      loading: false,
      standardData: [],
      categoryData: [],
      categoryId: "",
      subjectName: "",
      standardId: "",
      departmentId: "",
      subjectData: [],
      subjectId: "",
      startDate: new Date(),
    };
  }

  componentDidMount() {
    this.getSubject();
    this.getCategory();
  }

  getSubject = () => {
    axios
      .get(`${window.baseUrl}get-subject`)
      .then((response) => {
        if (response.data.status === 1) {
          // console.log(response.data);
          this.setState({
            subjectData: [...this.state.subjectData, ...response.data.data],
          });
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getCategory = () => {
    axios
      .get(`${window.baseUrl}get-category`)
      .then((response) => {
        if (response.data.status === 1) {
          this.setState({
            categoryData: [...this.state.categoryData, ...response.data.data],
          });
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onFileChange = (e) => {
    // Update the state
    this.setState({ selectedFile: e.target.files[0] });
  };

  onSubmit = () => {
    this.setState({ loading: true });
    const formData = new FormData();

    formData.append("pdfFile", this.state.selectedFile);
    formData.append("subjectId", this.state.subjectId);
    formData.append("categoryId", this.state.categoryId);
    formData.append("year", this.state.startDate.getFullYear());

    axios
      .post(`${window.baseUrl}add-book-paper-note`, formData)
      .then((response) => {
        // console.log(response);
        if (response.data.status === 1) {
          // console.log(response.data);
          this.setState(
            {
              loading: false,
              snackOpen: true,
              snackVarient: "success",
              subjectId: "",
              categoryId: "",
              startDate: new Date(),
              snackMessage: response.data.message,
              categoryData: [],
            },
            () => {
              this.getSubject();
              this.getCategory();
            }
          );
        } else {
          this.setState({
            loading: false,
            snackOpen: true,
            snackVarient: "error",
            snackMessage: response.data.message,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleClose = () => {
    this.setState({
      snackOpen: false,
    });
  };

  render() {
    const columns = [
      { field: "id", headerName: "ID", width: 70 },
      { field: "title", headerName: "Standard Name", width: 200 },
      { field: "stream", headerName: "Stream Name", width: 200 },
    ];

    const rows = this.state.standardData;

    const Alert = React.forwardRef(function Alert(props, ref) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    return (
      <main className="container mt-5">
        <Snackbar
          open={this.state.snackOpen}
          autoHideDuration={3000}
          onClose={this.handleClose}
        >
          <Alert severity={this.state.snackVarient} sx={{ width: 400 }}>
            {this.state.snackMessage}
          </Alert>
        </Snackbar>
        <div className="w-100">
          <Card className="w-100 p-3">
            <CardContent>
              <h4 className="text-body">Manage Books/Notes/Papers</h4>

              <div className="row">
                <div className="col-md-6">
                  <Card className="my-4">
                    <CardContent className="m-3">
                      <h5 className="text-body">Add Books/Paper/Notes</h5>

                      <div className="mt-4">
                        <div className="input-group mb-3">
                          <label className="input-group-text" htmlFor="pdfFile">
                            Upload
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            id="pdfFile"
                            name="selectedFile"
                            onChange={this.onFileChange}
                          />
                        </div>
                      </div>

                      <div className="mt-4 w-100">
                        <FormControl fullWidth size="small">
                          <InputLabel id="demo-simple-select-label">
                            Select Subject
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={this.state.subjectId}
                            label="Select Subject"
                            name="subjectId"
                            onChange={this.onChange}
                          >
                            {this.state.subjectData.map((e) => (
                              <MenuItem key={e.id} value={e.id}>
                                {e.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>

                      <div className="mt-4 w-100">
                        <FormControl fullWidth size="small">
                          <InputLabel id="demo-simple-select-label">
                            Select Category
                          </InputLabel>
                          <Select
                            labelId="department-select-label"
                            id="department-select"
                            value={this.state.categoryId}
                            label="Select Category"
                            name="categoryId"
                            onChange={this.onChange}
                          >
                            {this.state.categoryData.map((e) => (
                              <MenuItem key={e.id} value={e.id}>
                                {e.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="mt-4 w-100">
                        <DatePicker
                          selected={this.state.startDate}
                          onChange={(date) =>
                            this.setState({ startDate: date })
                          }
                          dateFormat="dd/MM/yyyy"
                          name="publishedDate"
                          value={this.state.startDate}
                        />
                      </div>

                      <div className="mt-4">
                        <LoadingButton
                          loading={this.state.loading}
                          variant="contained"
                          className="px-4"
                          onClick={this.onSubmit}
                        >
                          Submit
                        </LoadingButton>
                      </div>
                    </CardContent>
                  </Card>
                </div>
                <div className="col-md-6">
                  <Card className="my-4">
                    <CardContent>
                      <h5 className="text-body">List Standard</h5>

                      <div>
                        <div style={{ height: 400, width: "100%" }}>
                          <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                          />
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </main>
    );
  }
}

export default AddPaperBook;
