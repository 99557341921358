import React, { Component } from "react";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import CardContent from "@mui/material/CardContent";
import LoadingButton from "@material-ui/lab/LoadingButton";
import { DataGrid } from "@mui/x-data-grid";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import axios from "axios";

export class AddCollege extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collegeIcon: null,
      collegeImage: null,
      snackOpen: false,
      collegeName: "",
      collegeSummary: "",
      collegeEstablished: "",
      snackMessage: "",
      snackVarient: "",
      loading: false,
      bookData: [],
      collegeCity: [],
      collegeCityId: "",
      collegeType: "",
      collegePhone: "",
      collegeEmail: "",
      collegeLocalAddress: "",
      collegeBrochure: "",
      collegeLat: "",
      collegeLong: "",
      collegeCourseOffered: "",
    };
  }

  componentDidMount() {
    // this.getBook();
    this.getCollegeCity();
  }

  getCollegeCity = () => {
    axios
      .get(`${window.baseUrl}get-college-city`)
      .then((response) => {
        if (response.data.status === 1) {
          console.table(response.data.data);
          this.setState({
            collegeCity: [...this.state.collegeCity, ...response.data.data],
          });
        } else {
          // this.setState({});
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getBook = () => {
    axios
      .get(`${window.baseUrl}get-book`)
      .then((response) => {
        if (response.data.status === 1) {
          console.table(response.data.data);
          this.setState({
            bookData: [...this.state.bookData, ...response.data.data],
          });
        } else {
          // this.setState({});
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  onFileChange = (e) => {
    // Update the state
    this.setState({ [e.target.name]: e.target.files[0] });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = () => {
    this.setState({ loading: true });
    const formData = new FormData();

    formData.append("collegeIcon", this.state.collegeIcon);
    formData.append("collegeImage", this.state.collegeImage);
    formData.append("name", this.state.collegeName);
    formData.append("city_id", this.state.collegeCityId);
    formData.append("summary", this.state.collegeSummary);
    formData.append("established", this.state.collegeEstablished);
    formData.append("type", this.state.collegeType);
    formData.append("course_offered", this.state.collegeCourseOffered);
    formData.append("phone", this.state.collegePhone);
    formData.append("email", this.state.collegeEmail);
    formData.append("local_address", this.state.collegeLocalAddress);
    formData.append("brochure_link", this.state.collegeBrochure);
    formData.append("map_lat", this.state.collegeLat);
    formData.append("map_long", this.state.collegeLong);

    // console.log(formData.getAll('resumeDoc'));
    axios
      .post(`${window.baseUrl}add-college`, formData)
      .then((response) => {
        if (response.data.status === 1) {
          console.log(response.data);
          this.setState(
            {
              loading: false,
              snackOpen: true,
              snackVarient: "success",
              snackMessage: response.data.message,
              collegeIcon: null,
              collegeImage: null,
              collegeName: "",
              collegeSummary: "",
              collegeEstablished: "",
              collegeCity: [],
              collegeCityId: "",
              collegeType: "",
              collegePhone: "",
              collegeEmail: "",
              collegeLocalAddress: "",
              collegeBrochure: "",
              collegeLat: "",
              collegeLong: "",
              collegeCourseOffered: "",
            },
            () => {
              this.getBook();
            }
          );
        } else {
          this.setState({
            loading: false,
            snackOpen: true,
            snackVarient: "error",
            snackMessage: response.data.message,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleClose = () => {
    this.setState({
      snackOpen: false,
    });
  };

  render() {
    const columns = [
      { field: "id", headerName: "ID", width: 70 },
      {
        field: "cover_image",
        headerName: "Book Cover",
        width: 150,
        height: 200,
        renderCell: (params) => (
          <a href={params.value} target="_blank" rel="noreferrer">
            <img
              src={params.value}
              alt="Book cover"
              style={{ width: "150px", height: "200px" }}
            />
          </a>
        ),
      },
      {
        field: "title",
        headerName: "Book Title",
        width: 150,
      },
      {
        field: "affiliate_url",
        headerName: "Affliate Url",
        width: 150,
        renderCell: (params) => (
          <a href={params.value} target="_blank" rel="noreferrer">
            View Link
          </a>
        ),
      },
    ];

    const rows = this.state.bookData;

    const Alert = React.forwardRef(function Alert(props, ref) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    return (
      <main className="container mt-4">
        <Snackbar
          open={this.state.snackOpen}
          autoHideDuration={3000}
          onClose={this.handleClose}
        >
          <Alert severity={this.state.snackVarient} sx={{ width: 400 }}>
            {this.state.snackMessage}
          </Alert>
        </Snackbar>
        <div className="w-100">
          <div className="row">
            <div className="col-md-12">
              <Card className="my-2">
                <CardContent className="m-3">
                  <h5 className="text-body">Add College</h5>
                  <div className="row">
                    <div className="mt-4 col-md-6">
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                          Select City
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={this.state.collegeCityId}
                          label="Select College"
                          name="collegeCityId"
                          onChange={this.onChange}
                        >
                          {this.state.collegeCity.map((e) => (
                            <MenuItem key={e.id} value={e.id}>
                              {e.city_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div className="mt-4 col-md-6">
                      <TextField
                        id="outlined-basic"
                        label="College Name"
                        variant="outlined"
                        className="w-100"
                        size="small"
                        name="collegeName"
                        value={this.state.collegeName}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>

                  <div className="mt-4 w-100">
                    <TextField
                      id="outlined-basic"
                      label="College Summary"
                      variant="outlined"
                      className="w-100"
                      size="small"
                      name="collegeSummary"
                      value={this.state.collegeSummary}
                      onChange={this.onChange}
                      multiline
                      rows={3}
                    />
                  </div>
                  <div className="row">
                    <div className="mt-4 col-md-6">
                      <TextField
                        id="outlined-basic"
                        label="College Established"
                        variant="outlined"
                        className="w-100"
                        size="small"
                        name="collegeEstablished"
                        value={this.state.collegeEstablished}
                        onChange={this.onChange}
                      />
                    </div>
                    <div className="mt-4 col-md-6">
                      <FormControl fullWidth size="small">
                        <InputLabel id="college-type">
                          Select College Type
                        </InputLabel>
                        <Select
                          labelId="college-type"
                          id="college-type-select"
                          value={this.state.collegeType}
                          label="Select College Type"
                          name="collegeType"
                          onChange={this.onChange}
                        >
                          <MenuItem value="Private">Private</MenuItem>
                          <MenuItem value="Public">Public</MenuItem>
                          <MenuItem value="Government">Government</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="row">
                    <div className="mt-4 col-md-6">
                      <TextField
                        id="outlined-basic"
                        label="College Phone"
                        variant="outlined"
                        className="w-100"
                        size="small"
                        name="collegePhone"
                        value={this.state.collegePhone}
                        onChange={this.onChange}
                      />
                    </div>
                    <div className="mt-4 col-md-6">
                      <TextField
                        id="outlined-basic"
                        label="College Email"
                        variant="outlined"
                        className="w-100"
                        size="small"
                        name="collegeEmail"
                        value={this.state.collegeEmail}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                  <div className="mt-4 w-100">
                    <TextField
                      id="outlined-basic"
                      label="College Local Address"
                      variant="outlined"
                      className="w-100"
                      size="small"
                      name="collegeLocalAddress"
                      value={this.state.collegeLocalAddress}
                      onChange={this.onChange}
                      multiline
                      rows={2}
                    />
                  </div>
                  <div className="row">
                    <div className="mt-4 col-md-6">
                      <TextField
                        id="outlined-basic"
                        label="College Brochure Link"
                        variant="outlined"
                        className="w-100"
                        size="small"
                        name="collegeBrochure"
                        value={this.state.collegeBrochure}
                        onChange={this.onChange}
                      />
                    </div>
                    <div className="mt-4 col-md-6">
                      <TextField
                        id="outlined-basic"
                        label="College Course Offered"
                        variant="outlined"
                        className="w-100"
                        size="small"
                        name="collegeCourseOffered"
                        value={this.state.collegeCourseOffered}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="mt-4 col-6">
                      <TextField
                        id="outlined-basic"
                        label="College Latittude"
                        variant="outlined"
                        className="w-100"
                        size="small"
                        name="collegeLat"
                        value={this.state.collegeLat}
                        onChange={this.onChange}
                      />
                    </div>
                    <div className="mt-4 col-6">
                      <TextField
                        id="outlined-basic"
                        label="College Longitude"
                        variant="outlined"
                        className="w-100"
                        size="small"
                        name="collegeLong"
                        value={this.state.collegeLong}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                  <div className="mt-4">
                    <div className="input-group mb-3">
                      <label className="input-group-text" htmlFor="collegeIcon">
                        College Icon
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        id="collegeIcon"
                        name="collegeIcon"
                        onChange={this.onFileChange}
                      />
                    </div>
                  </div>

                  <div className="mt-4">
                    <div className="input-group mb-3">
                      <label
                        className="input-group-text"
                        htmlFor="collegeImage"
                      >
                        College Image
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        id="collegeImage"
                        name="collegeImage"
                        onChange={this.onFileChange}
                      />
                    </div>
                  </div>

                  <div className="mt-4">
                    <LoadingButton
                      loading={this.state.loading}
                      variant="contained"
                      className="px-4"
                      onClick={this.onSubmit}
                    >
                      Submit
                    </LoadingButton>
                  </div>
                </CardContent>
              </Card>
            </div>
            <div className="col-md-6">
              <Card className="my-4">
                <CardContent>
                  <h5 className="text-body">List Bestselling Books</h5>

                  <div>
                    <div style={{ height: 400, width: "100%" }}>
                      <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                      />
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default AddCollege;
