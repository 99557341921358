import React, { Component } from "react";
import axios from "axios";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MuiAlert from "@mui/material/Alert";
import LoadingButton from "@material-ui/lab/LoadingButton";
import { Link } from "react-router-dom";

export class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      error: false,
      errorEmail: "",
      errorPassword: "",
      alert: false,
      severity: "",
      alertMessage: "",
      loading: false,
    };

    document.title = "Please login here - News Admin";

    if (localStorage.getItem("id") !== null) {
      window.location.replace("/dashboard");
    }
    console.log(localStorage.getItem("id"));
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ error: false, errorEmail: "", errorPassword: "" });
  };

  submitHandler = async (e) => {
    e.preventDefault();

    this.setState({ loading: true });
    if (this.state.email === "") {
      this.setState({ error: true, errorEmail: "Email is Required" });
      this.setState({ loading: false });
    } else if (this.state.password === "") {
      this.setState({ loading: true });
      this.setState({ error: true, errorPassword: "Password is Required" });
      this.setState({ loading: false });
    } else {
      this.setState({ loading: true });
      let data = await axios.post(`${window.baseUrl}/login`, this.state);

      if (data.data.status === 0) {
        this.setState({
          alert: true,
          severity: "error",
          alertMessage: data.data.message,
          loading: false,
        });
      } else {
        // this.setState({
        //   alert: true,
        //   severity: "success",
        //   alertMessage: data.data.message,
        // });

        localStorage.setItem("id", data.data.data.id);
        localStorage.setItem("email", data.data.data.email);
        localStorage.setItem("name", data.data.data.name);
        await window.location.replace("/dashboard");
      }
    }
  };

  render() {
    const Alert = React.forwardRef(function Alert(props, ref) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    return (
      <Grid container spacing={2} sx={{ mt: 5 }}>
        <Grid item xs={3} sx={{ margin: "auto" }}>
          <Card sx={{ minWidth: 275 }} className="py-2 px-4">
            <CardContent>
              <h1 className="h4 text-center text-muted pb-2">
                <img src="logo192.png" alt="Logo" style={{ width: "100px" }} />
              </h1>
              {this.state.alert ? (
                <Alert severity={this.state.severity} className="my-4">
                  {this.state.alertMessage}
                </Alert>
              ) : (
                ""
              )}

              <form onSubmit={this.submitHandler}>
                <div className="mb-4">
                  <TextField
                    error={this.state.errorEmail !== ""}
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    type="email"
                    className="w-100"
                    name="email"
                    value={this.state.email}
                    onChange={this.changeHandler}
                    helperText={this.state.error ? this.state.errorEmail : ""}
                  />
                </div>
                <div className="mb-4">
                  <TextField
                    error={this.state.errorPassword !== ""}
                    id="outlined-password-input"
                    label="Password"
                    type="password"
                    autoComplete="current-password"
                    className="w-100"
                    name="password"
                    value={this.state.password}
                    onChange={this.changeHandler}
                    helperText={
                      this.state.error ? this.state.errorPassword : ""
                    }
                  />
                </div>

                {/* <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  className="w-100 font-weight-bold mb-3 bg-pink"
                >
                  Log in
                </Button> */}
                <LoadingButton
                  type="submit"
                  size="large"
                  loading={this.state.loading}
                  variant="contained"
                  className="bg-pink w-100"
                >
                  Login
                </LoadingButton>
              </form>
              <div className="my-4">
                <Link to="register" className="text-pink" color="primary">
                  Create account here
                </Link>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default Login;
