import React, { Component } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import LoadingButton from "@material-ui/lab/LoadingButton";
import { DataGrid } from "@mui/x-data-grid";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";

export class Department extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFile: null,
      departmentLabel: "",
      snackOpen: false,
      snackMessage: "",
      snackVarient: "",
      loading: false,
      departmentData: [],
      streamData: [],
      stream: "",
    };
  }

  componentDidMount() {
    this.getDepartment();
    this.getStreams();
  }

  getDepartment = () => {
    axios
      .get(`${window.baseUrl}get-department`)
      .then((response) => {
        if (response.data.status === 1) {
          console.log(response.data);
          this.setState({
            departmentData: [...this.state.departmentData, ...response.data.data],
          });
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getStreams = () => {
    axios
      .get(`${window.baseUrl}get-stream`)
      .then((response) => {
        if (response.data.status === 1) {
          this.setState({
            streamData: [...this.state.streamData, ...response.data.data],
          });
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  

  onFileChange = (e) => {
    // Update the state
    this.setState({ selectedFile: e.target.files[0] });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = () => {
    this.setState({ loading: true });
    const formData = new FormData();

    formData.append("departmentName", this.state.departmentLabel);
    formData.append("streamId", this.state.stream);
    formData.append("myFile", this.state.selectedFile);

    axios
      .post(`${window.baseUrl}add-department`, formData)
      .then((response) => {
        console.log(response);
        if (response.data.status === 1) {
          console.log(response.data);
          this.setState(
            {
              loading: false,
              snackOpen: true,
              snackVarient: "success",
              departmentLabel: "",
              snackMessage: response.data.message,
              departmentData: [],
              streamData: [],
              stream: "",
            },
            () => {
              this.getDepartment();
              this.getStreams();
            }
          );
        } else {
          this.setState({
            loading: false,
            snackOpen: true,
            snackVarient: "error",
            snackMessage: response.data.message,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleClose = () => {
    this.setState({
      snackOpen: false,
    });
  };

  render() {
    const columns = [
      { field: "id", headerName: "ID", width: 70 },
      { field: "title", headerName: "Category Name", width: 200 },
      { field: "stream", headerName: "Stream Name", width: 200 },
      {
        field: "icon",
        headerName: "Icon",
        width: 75,
        renderCell: (params) => (
          <img src={params.value} alt="Category Icon" className="w-100 h-100 p-1" />
        ),
      },
    ];

    const rows = this.state.departmentData;

    const Alert = React.forwardRef(function Alert(props, ref) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    return (
      <main className="container mt-5">
        <Snackbar
          open={this.state.snackOpen}
          autoHideDuration={3000}
          onClose={this.handleClose}
        >
          <Alert severity={this.state.snackVarient} sx={{ width: 400 }}>
            {this.state.snackMessage}
          </Alert>
        </Snackbar>
        <div className="w-100">
          <Card className="w-100 p-3">
            <CardContent>
              <h4 className="text-body">Manage Department</h4>

              <div className="row">
                <div className="col-md-6">
                  <Card className="my-4">
                    <CardContent className="m-3">
                      <h5 className="text-body">Add Department</h5>

                      <div className="mt-5 w-100">
                        <TextField
                          id="outlined-basic"
                          label="Department Label"
                          variant="outlined"
                          className="w-100"
                          size="small"
                          name="departmentLabel"
                          value={this.state.departmentLabel}
                          onChange={this.onChange}
                        />
                      </div>

                      <div className="mt-4 w-100">
                        <FormControl fullWidth size="small">
                          <InputLabel id="demo-simple-select-label">
                            Select Stream
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={this.state.stream}
                            label="Select Stream"
                            name="stream"
                            onChange={this.onChange}
                          >
                            {this.state.streamData.map((e) => (
                              <MenuItem key={e.id} value={e.id}>
                                {e.title}
                              </MenuItem>
                            ))}

                            {/* <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem> */}
                          </Select>
                        </FormControl>
                      </div>

                      <div className="mt-4">
                        <div className="input-group mb-3">
                          <label
                            className="input-group-text"
                            htmlFor="streamIcon"
                          >
                            Upload
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            id="streamIcon"
                            name="selectedFile"
                            onChange={this.onFileChange}
                          />
                        </div>
                      </div>

                      <div className="mt-4">
                        <LoadingButton
                          loading={this.state.loading}
                          variant="contained"
                          className="px-4"
                          onClick={this.onSubmit}
                        >
                          Submit
                        </LoadingButton>
                      </div>
                    </CardContent>
                  </Card>
                </div>
                <div className="col-md-6">
                  <Card className="my-4">
                    <CardContent>
                      <h5 className="text-body">List Departments</h5>

                      <div>
                        <div style={{ height: 400, width: "100%" }}>
                          <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                          />
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </main>
    );
  }
}

export default Department;
