import "./App.css";
import { Login } from "./Authentication/Login";
import { Register } from "./Authentication/Register";
import { Dashboard } from "./pages/Dashboard";
import Nav from "./pages/includes/Nav";
import { AddNews } from "./pages/AddNews";
import { AllNews } from "./pages/AllNews";
import { Stream } from "./pages/Stream";
import { Department } from "./pages/Department";
import { Standard } from "./pages/Standard";
import { Category } from "./pages/Category";
import { AddPaperBook } from "./pages/AddPaperBook";
import { AddSubject } from "./pages/AddSubject";
import { AddResume } from "./pages/AddResume";
import { AddBook } from "./pages/AddBook";
import { AddCollege } from "./pages/AddCollege";
import { AddCollegeCourse } from "./pages/AddCollegeCourse";
import { HashRouter , Routes, Route, BrowserRouter } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#23876C",
    },
    secondary: {
      main: "#880e4f",
    },
  },
});

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="dashboard" element={<Nav />}>
              <Route index element={<Dashboard />} />
              <Route path="add-news" element={<AddNews />} />
              <Route path="all-news" element={<AllNews />} />
              <Route path="add-category" element={<Category />} />
              <Route path="add-stream" element={<Stream />} />
              <Route path="add-department" element={<Department />} />
              <Route path="add-standard" element={<Standard />} />
              <Route path="add-paper-book" element={<AddPaperBook />} />
              <Route path="add-subject" element={<AddSubject />} />
              <Route path="add-resume" element={<AddResume />} />
              <Route path="add-book" element={<AddBook />} />
              <Route path="add-college" element={<AddCollege />} />
              <Route path="add-college-course" element={<AddCollegeCourse />} />
            </Route>

            <Route path="/" element={<Login />} />
            <Route path="/register" element={<Register />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}

export default App;
