import React, { Component } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import LoadingButton from "@material-ui/lab/LoadingButton";
import { DataGrid } from "@mui/x-data-grid";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Autocomplete from "@mui/material/Autocomplete";

export class AddCollegeCourse extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFile: null,
      streamLabel: "",
      snackOpen: false,
      snackMessage: "",
      snackVarient: "",
      loading: false,
      collegeData: [],
      collegeId: "",
      courseStandard: "",
      studyMode: "",
      fee: "",
      courseDuration: "",
      inputFields: [{ courseName: "", fee: "" }],
    };
  }

  componentDidMount() {
    this.getCollege();
  }

  getCollege = () => {
    axios
      .get(`${window.baseUrl}get-college`)
      .then((response) => {
        if (response.data.status === 1) {
          this.setState({
            collegeData: [...this.state.collegeData, ...response.data.data],
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  onInputChange = (e, value) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onChange = (index, e) => {
    const values = [...this.state.inputFields];
    values[index][e.target.name] = e.target.value;
    this.setState({
      inputFields: values,
    });
  };

  onSubmit = () => {
    this.setState({ loading: true });
    const body = {
      courseData: this.state.inputFields,
      college_id: this.state.collegeId,
      course_standard: this.state.courseStandard,
      duration: this.state.courseDuration,
      fee: this.state.fee,
      study_mode: this.state.studyMode,
    };
    axios
      .post(`${window.baseUrl}add-college-course`, body)
      .then((response) => {
        if (response.data.status === 1) {
          this.setState({
            loading: false,
            snackOpen: true,
            snackVarient: "success",
            snackMessage: response.data.message,
            collegeData: [],
          });
        } else {
          this.setState({
            loading: false,
            snackOpen: true,
            snackVarient: "error",
            snackMessage: response.data.message,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleClose = () => {
    this.setState({
      snackOpen: false,
    });
  };

  handleAddFields = () => {
    this.setState({
      inputFields: [...this.state.inputFields, { courseName: "", fee: "" }],
    });
  };

  handleRemoveFields = (index) => {
    const values = [...this.state.inputFields];
    values.splice(index, 1);
    this.setState({
      inputFields: values,
    });
  };

  render() {
    const columns = [
      { field: "id", headerName: "ID", width: 70 },
      { field: "title", headerName: "Stream Name", width: 250 },
      {
        field: "icon",
        headerName: "Icon",
        width: 80,
        renderCell: (params) => (
          <img src={params.value} alt="Stream Icon" className="w-100 h-100" />
        ),
      },
    ];

    const rows = this.state.collegeData;

    const Alert = React.forwardRef(function Alert(props, ref) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    return (
      <main className="container mt-5">
        <Snackbar
          open={this.state.snackOpen}
          autoHideDuration={3000}
          onClose={this.handleClose}
        >
          <Alert severity={this.state.snackVarient} sx={{ width: 400 }}>
            {this.state.snackMessage}
          </Alert>
        </Snackbar>
        <div className="w-100">
          <Card className="w-100 p-3">
            <CardContent>
              <h4 className="text-body">Manage College Course</h4>

              <div className="row">
                <div className="col-md-8">
                  <Card className="my-4">
                    <CardContent className="m-3">
                      <h5 className="text-body">Add College Course</h5>
                      <div className="mt-5 mb-4 col-md-10">
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={this.state.collegeData.map(
                            (option) => option.name
                          )}
                          size="small"
                          sx={{ width: "100%" }}
                          renderInput={(params) => (
                            <TextField {...params} label="Select College" />
                          )}
                          name="collegeId"
                          onChange={(event, value) =>
                            this.setState({ collegeId: value })
                          }
                        />
                      </div>
                      <div className="row">
                        <div className="mb-4 col-md-5">
                          <TextField
                            label="Course Standard"
                            variant="outlined"
                            className="w-100"
                            size="small"
                            name="courseStandard"
                            value={this.state.courseStandard}
                            onChange={(event, value) =>
                              this.onInputChange(event, value)
                            }
                          />
                        </div>
                        <div className="mb-4 col-md-5">
                          <TextField
                            label="Course Duration"
                            variant="outlined"
                            className="w-100"
                            size="small"
                            name="courseDuration"
                            value={this.state.courseDuration}
                            onChange={(event, value) =>
                              this.onInputChange(event, value)
                            }
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="mb-4 col-md-5">
                          <TextField
                            label="Annual Fee"
                            variant="outlined"
                            className="w-100"
                            size="small"
                            name="fee"
                            value={this.state.fee}
                            onChange={(event, value) =>
                              this.onInputChange(event, value)
                            }
                          />
                        </div>
                        <div className="mb-4 col-md-5">
                          <TextField
                            label="Study Mode"
                            variant="outlined"
                            className="w-100"
                            size="small"
                            name="studyMode"
                            value={this.state.studyMode}
                            onChange={(event, value) =>
                              this.onInputChange(event, value)
                            }
                          />
                        </div>
                      </div>

                      {this.state.inputFields.map((e, index) => (
                        <div className="row" key={index}>
                          <div className="mt-2 col-md-5">
                            <TextField
                              label="Course Name"
                              variant="outlined"
                              className="w-100"
                              size="small"
                              name="courseName"
                              value={e.courseName}
                              onChange={(e) => {
                                this.onChange(index, e);
                              }}
                            />
                          </div>

                          <div className="mt-2  col-md-5">
                            <TextField
                              label="Fee"
                              variant="outlined"
                              className="w-100"
                              size="small"
                              name="fee"
                              value={e.fee}
                              onChange={(e) => {
                                this.onChange(index, e);
                              }}
                            />
                          </div>
                          <div className="py-3 col-md-2">
                            <Stack
                              direction="row"
                              spacing={1}
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <IconButton
                                aria-label="delete"
                                color="primary"
                                onClick={() => this.handleAddFields()}
                              >
                                <AddIcon />
                              </IconButton>
                              <IconButton
                                aria-label="delete"
                                color="secondary"
                                onClick={() => {
                                  if (index !== 0) {
                                    this.handleRemoveFields(index);
                                  }
                                }}
                              >
                                <RemoveIcon />
                              </IconButton>
                            </Stack>
                          </div>
                        </div>
                      ))}

                      <div className="mt-4">
                        <LoadingButton
                          loading={this.state.loading}
                          variant="contained"
                          className="px-4"
                          onClick={this.onSubmit}
                        >
                          Submit
                        </LoadingButton>
                      </div>
                    </CardContent>
                  </Card>
                </div>
                <div className="col-md-6">
                  <Card className="my-4">
                    <CardContent>
                      <h5 className="text-body">List Streams</h5>

                      <div>
                        <div style={{ height: 400, width: "100%" }}>
                          <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                          />
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </main>
    );
  }
}

export default AddCollegeCourse;
