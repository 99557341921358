import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { Outlet } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

export default function Nav() {
  const logoutHandler = async () => {
    await localStorage.clear();
    window.location.replace("/");
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorLv, setAnchorLv] = React.useState(null);
  const [contentLv, setContentLv] = React.useState(null);

  const open = Boolean(anchorEl);
  const openLv = Boolean(anchorLv);
  const openCt = Boolean(contentLv);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickLevels = (event) => {
    setAnchorLv(event.currentTarget);
  };

  const handleClickContent = (event) => {
    setContentLv(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseLevels = () => {
    setAnchorLv(null);
  };

  const handleCloseContent = () => {
    setContentLv(null);
  };

  // console.log(localStorage.getItem("id"));
  return (
    <>
      <AppBar position="static" className="py-0">
        <Toolbar className="container">
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            EduBuddy
          </Typography>

          <Link to="" className="text-white text-decoration-none px-3">
            Dashboard
          </Link>

          <div>
            <span
              id="news-dropdown"
              aria-controls={open ? "news-dropdown" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              className="text-white text-decoration-none px-3 font-weight-bold"
              role="button"
            >
              News
            </span>
            <Menu
              id="news-dropdown"
              aria-labelledby="news-dropdown"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={handleClose} className="nav-submenu-width">
                <Link to="add-news" className="text-body text-decoration-none">
                  Add News
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link to="all-news" className="text-body text-decoration-none">
                  All News
                </Link>
              </MenuItem>
            </Menu>
          </div>
          <div>
            <span
              id="demo-positioned-button"
              aria-controls={openLv ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openLv ? "true" : undefined}
              onClick={handleClickLevels}
              className="text-white text-decoration-none px-3 font-weight-bold"
              role="button"
            >
              Levels
            </span>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorLv}
              open={openLv}
              onClose={handleCloseLevels}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={handleClose} className="nav-submenu-width">
                <Link
                  to="add-category"
                  className="text-body text-decoration-none"
                >
                  Add Category
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose} className="nav-submenu-width">
                <Link
                  to="add-stream"
                  className="text-body text-decoration-none"
                >
                  Add Stream
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link
                  to="add-department"
                  className="text-body text-decoration-none"
                >
                  Add Departments
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link
                  to="add-standard"
                  className="text-body text-decoration-none"
                >
                  Add Standard
                </Link>
              </MenuItem>
            </Menu>
          </div>
          <div>
            <span
              id="content-menu"
              aria-controls={contentLv ? "content-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={contentLv ? "true" : undefined}
              onClick={handleClickContent}
              className="text-white text-decoration-none px-3 font-weight-bold"
              role="button"
            >
              Add Content
            </span>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={contentLv}
              open={openCt}
              onClose={handleCloseContent}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={handleClose} className="nav-submenu-width">
                <Link
                  to="add-subject"
                  className="text-body text-decoration-none"
                >
                  Add Subject
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose} className="nav-submenu-width">
                <Link
                  to="add-paper-book"
                  className="text-body text-decoration-none"
                >
                  Add Paper/Book/Notes
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose} className="nav-submenu-width">
                <Link
                  to="add-resume"
                  className="text-body text-decoration-none"
                >
                  Add Resume
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose} className="nav-submenu-width">
                <Link
                  to="add-book"
                  className="text-body text-decoration-none"
                >
                  Bestselling Books
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose} className="nav-submenu-width">
                <Link
                  to="add-stream"
                  className="text-body text-decoration-none"
                >
                  Add Home Slider
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose} className="nav-submenu-width">
                <Link
                  to="add-college"
                  className="text-body text-decoration-none"
                >
                  Add College
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose} className="nav-submenu-width">
                <Link
                  to="add-college-course"
                  className="text-body text-decoration-none"
                >
                  Add College Course
                </Link>
              </MenuItem>
            </Menu>
          </div>
          <Link
            className="text-white text-decoration-none px-3"
            to=""
            onClick={logoutHandler}
          >
            Logout
          </Link>
        </Toolbar>
      </AppBar>

      <Outlet />
    </>
  );
}

// export default Nav;

// import React from 'react'

// export default function Nav() {
//   return (
//     <div>Nav</div>
//   )
// }
