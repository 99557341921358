import React, { Component } from "react";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import CardContent from "@mui/material/CardContent";
import LoadingButton from "@material-ui/lab/LoadingButton";
import { DataGrid } from "@mui/x-data-grid";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import axios from "axios";

export class AddBook extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFile: null,
      selectedDoc: null,
      categoryLabel: "",
      snackOpen: false,
      bookTitle: "",
      affUrl: "",
      snackMessage: "",
      snackVarient: "",
      loading: false,
      bookData: [],
    };
  }

  componentDidMount() {
    this.getBook();
  }

  getBook = () => {
    axios
      .get(`${window.baseUrl}get-book`)
      .then((response) => {
        if (response.data.status === 1) {
          console.log(response.data);
          this.setState({
            bookData: [...this.state.bookData, ...response.data.data],
          });
        } else {
          // this.setState({});
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  onFileChange = (e) => {
    // Update the state
    this.setState({ [e.target.name]: e.target.files[0] });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = () => {
    this.setState({ loading: true });
    const formData = new FormData();

    formData.append("bookCover", this.state.bookCover);
    formData.append("title", this.state.bookTitle);
    formData.append("affiliate_url", this.state.affUrl);

    // console.log(formData.getAll('resumeDoc'));
    axios
      .post(`${window.baseUrl}add-book`, formData)
      .then((response) => {
        if (response.data.status === 1) {
          console.log(response.data);
          this.setState(
            {
              loading: false,
              snackOpen: true,
              snackVarient: "success",
              bookTitle: "",
              affUrl: "",
              snackMessage: response.data.message,
              bookData: [],
              bookCover: null,
            },
            () => {
              this.getBook();
            }
          );
        } else {
          this.setState({
            loading: false,
            snackOpen: true,
            snackVarient: "error",
            snackMessage: response.data.message,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleClose = () => {
    this.setState({
      snackOpen: false,
    });
  };

  render() {
    const columns = [
      { field: "id", headerName: "ID", width: 70 },
      {
        field: "cover_image",
        headerName: "Book Cover",
        width: 150,
        height: 200,
        renderCell: (params) => (
          <a href={params.value} target="_blank" rel="noreferrer">
            <img
              src={params.value}
              alt="Book cover"
              style={{ width: "150px", height: "200px" }}
            />
          </a>
        ),
      },
      {
        field: "title",
        headerName: "Book Title",
        width: 150,
      },
      {
        field: "affiliate_url",
        headerName: "Affliate Url",
        width: 150,
        renderCell: (params) => (
          <a href={params.value} target="_blank" rel="noreferrer">
            View Link
          </a>
        ),
      },
    ];

    const rows = this.state.bookData;

    const Alert = React.forwardRef(function Alert(props, ref) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    return (
      <main className="container mt-5">
        <Snackbar
          open={this.state.snackOpen}
          autoHideDuration={3000}
          onClose={this.handleClose}
        >
          <Alert severity={this.state.snackVarient} sx={{ width: 400 }}>
            {this.state.snackMessage}
          </Alert>
        </Snackbar>
        <div className="w-100">
          <Card className="w-100 p-3">
            <CardContent>
              <h4 className="text-body">Manage Bestselling Books</h4>

              <div className="row">
                <div className="col-md-6">
                  <Card className="my-4">
                    <CardContent className="m-3">
                      <h5 className="text-body">Add Bestselling Books</h5>
                      <div className="mt-4 w-100">
                        <TextField
                          id="outlined-basic"
                          label="Book Title"
                          variant="outlined"
                          className="w-100"
                          size="small"
                          name="bookTitle"
                          value={this.state.bookTitle}
                          onChange={this.onChange}
                        />
                      </div>

                      <div className="mt-4 w-100">
                        <TextField
                          id="outlined-basic"
                          label="Affiliate Url"
                          variant="outlined"
                          className="w-100"
                          size="small"
                          name="affUrl"
                          value={this.state.affUrl}
                          onChange={this.onChange}
                        />
                      </div>

                      <div className="mt-4">
                        <div className="input-group mb-3">
                          <label
                            className="input-group-text"
                            htmlFor="bookCover"
                          >
                            Upload Book Cover
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            id="bookCover"
                            name="bookCover"
                            onChange={this.onFileChange}
                          />
                        </div>
                      </div>

                      <div className="mt-4">
                        <LoadingButton
                          loading={this.state.loading}
                          variant="contained"
                          className="px-4"
                          onClick={this.onSubmit}
                        >
                          Submit
                        </LoadingButton>
                      </div>
                    </CardContent>
                  </Card>
                </div>
                <div className="col-md-6">
                  <Card className="my-4">
                    <CardContent>
                      <h5 className="text-body">List Bestselling Books</h5>

                      <div>
                        <div style={{ height: 400, width: "100%" }}>
                          <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                          />
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </main>
    );
  }
}

export default AddBook;
