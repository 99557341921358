import React, { Component } from "react";
import TextField from "@mui/material/TextField";
import LoadingButton from "@material-ui/lab/LoadingButton";
import axios from "axios";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export class AddNews extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newsUrl: "",
      error: false,
      newsUrlFeed: "",
      loading: false,
      newsSubmitted: false,
      responseMsg: "",
      submittedResponse: [],
      sendNotification: false,
    };

    document.title = "Please Add News Using News Link";
  }

  handleChange = (e) => {
    this.setState({
      newsUrlFeed: "",
      error: false,
    });
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleCheck = (e) => {
    this.setState({ sendNotification: e.target.checked });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });

    if (this.state.newsUrl === "") {
      this.setState({
        error: true,
        newsUrlFeed: "News Url is Required",
        loading: false,
      });
    } else {
      let response = await axios.post(`${window.baseUrl}/add-news`, this.state);
      // console.log(response.data);
      if (response.data.status === 0) {
        this.setState({
          responseMsg: response.data.message,
        });
      }

      if (response.data.status === 1) {
        this.setState({
          newsSubmitted: true,
          newsUrl: "",
          submittedResponse: response.data.data,
          loading: false,
        });
      }
    }
  };
  render() {
    const { newsUrl } = this.state;
    return (
      <>
        <main className="container">
          <div className="card w-75 mx-auto p-5 mt-5">
            <div className="card-body">
              <h3 className="pb-5">Add New News Here</h3>
              <form onSubmit={this.handleSubmit}>
                <TextField
                  error={this.state.error}
                  className="w-100"
                  id="outlined-basic"
                  label="Enter News Link"
                  variant="outlined"
                  value={newsUrl}
                  onChange={this.handleChange}
                  name="newsUrl"
                  helperText={
                    this.state.newsUrlFeed ? this.state.newsUrlFeed : ""
                  }
                />
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Send Push Notification"
                    className="my-3"
                    name="sendNotification"
                    value={this.state.sendNotification}
                    onChange={this.handleCheck}
                  />
                </FormGroup>
                <LoadingButton
                  type="submit"
                  size="large"
                  variant="contained"
                  className="mt-3"
                  loading={this.state.loading}
                >
                  Add News
                </LoadingButton>
              </form>
            </div>
          </div>
          <div className="w-75 mx-auto p-0 mt-5">
            {this.state.newsSubmitted === true ? (
              <Card className="mb-5">
                <CardMedia
                  component="img"
                  height="200"
                  image={this.state.submittedResponse.imageUrl}
                  alt="green iguana"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {this.state.submittedResponse.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {this.state.submittedResponse.description}
                  </Typography>
                  <div className="py-2">
                    Published on: {this.state.submittedResponse.pubDate}
                  </div>
                  <div className="py-3">
                    <Stack direction="row" spacing={1}>
                      <Chip
                        label={this.state.submittedResponse.siteName}
                        color="primary"
                        size="small"
                      />
                    </Stack>
                  </div>
                </CardContent>
              </Card>
            ) : (
              // )
              <div className="card w-100 mx-auto">
                <div className="card-body">
                  <h4 className="py-2 text-center">
                    {this.state.responseMsg
                      ? this.state.responseMsg
                      : "Added News will be shown here"}
                  </h4>
                </div>
              </div>
            )}
          </div>
        </main>
      </>
    );
  }
}

export default AddNews;
